import React from 'react'
import Layout from '../../Layout'
import IMG1 from "../../assets/Rectangle1.png";
import IMG2 from "../../assets/Rectangle2.png";
import IMG3 from "../../assets/Rectangle3.png";
import IMG4 from "../../assets/Rectangle4.png";
import IMG5 from "../../assets/Rectangle5.png";
import IMG6 from "../../assets/Rectangle6.png";
import IMG7 from "../../assets/Rectangle7.png";
import IMG8 from "../../assets/Rectangle8.png";
import IMG9 from "../../assets/Rectangle9.png";
import VishwaLogo from "../../assets/VishwaLogo.png";
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import "./index.css";
import ICO1 from "../../assets/globalicon.svg";
import ICO2 from "../../assets/book.svg";
import ICO3 from "../../assets/shop.svg";
import ICO4 from "../../assets/hand.svg";
import ICO5 from "../../assets/verification.svg";
import PARTNERICO1 from "../../assets/make in india.svg";
import PARTNERICO2 from "../../assets/skill india.svg";
import PARTNERICO3 from "../../assets/fieo.svg";
import PARTNERICO4 from "../../assets/up govt.svg";


const imgs = [IMG1, IMG2, IMG3, IMG4, IMG5, IMG6, IMG7, IMG8, IMG9];
const icons = [
    { title: "Global E-commerce Skill Development", img: ICO1 },
    { title: "Compliance Check and Execution", img: ICO2 },
    { title: "Access to Global Markets", img: ICO3 },
    { title: "Hand Holding and Account Management", img: ICO4 },
    { title: "Demand Generation and Fulfillment", img: ICO5 },
];
const partenrs = [PARTNERICO1, PARTNERICO2, PARTNERICO3, PARTNERICO4];

export default function Home() {

    const navigationLabel = (p) => {
        const [Month, Year] = p.label.split(" ");
        return (
            <div className='d-flex align-items-center justify-content-between'>
                <span className='month-label'>{Month}</span>
                <span className='year-label'>{Year}</span>
            </div>
        );
    };

    return (
        <Layout>
            <div className='primary-background h-100'>
                <div class="container">
                    <div class="row gx-5">
                        <div class="col-12 col-lg-7">
                            <p className='title'>Connecting MSMEs to the World</p>
                            <div className='container '>
                                <div class="row g-3">
                                    {imgs.map((img, i) => (
                                        <div className="img-preview col-4" key={i}>
                                            <img src={img} alt="img" />
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-lg-5">
                            <img src={VishwaLogo} className='mt-5 mt-lg-2' alt="vishwa" />
                            <p className='title mt-lg-5'>Event Date</p>
                            <Calendar
                                onChange={(e) => {
                                    console.log(e)
                                }}
                                view='month'
                                navigationLabel={navigationLabel}
                                selectRange={true}
                                defaultValue={[new Date("2023-10-18"), new Date("2023-10-17")]}
                                value={[new Date("2023-10-18"), new Date("2023-10-17")]}
                            />
                        </div>
                    </div>

                    <div class="mt-5 row gx-5">
                        <div class="col-12 col-lg-12 mt-5">
                            <p className='title'>Program Benefits</p>
                            <div className='container '>
                                <div class="row g-3">
                                    {icons.map((p, i) => (
                                        <div class="col program-card-parent" style={{ maxWidth: 238, minWidth: 238 }}>
                                            <div class="card program-card">
                                                <div className='program-icon-container'>
                                                    <img src={p.img} class="card-img-top" alt="ico" />
                                                </div>
                                                <div className='card-content'>
                                                    <p class="card-text">{p.title}</p>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="mt-5 row gx-5">
                        <div class="col-12 mt-5 pt-2 bg-white rounded-3">
                            <p className='title'>Our Partners</p>
                            <div className='container d-flex justify-content-center mb-4'>
                            <div class="d-flex flex-column flex-md-row align-items-center justify-content-around w-75">
                                    {partenrs.map((p, i) => (
                                        <div className='my-4 my-md-0'>
                                            <img src={p} alt="ico" />
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </Layout>
    )
}
