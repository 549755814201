import React from 'react';
import {
    Navbar,
    NavbarBrand,
    Nav,
    NavItem,
} from 'reactstrap';
import Logo from "../assets/Logo.png";
import "./styles.css";
import { useNavigate } from 'react-router-dom';

export default function Layout(props) {
    const navigate = useNavigate();

    const applyInactiveClass = (path) => window.location.pathname !== "/" && !window.location.pathname.includes(path);
    return (
        <div className='container d-flex flex-column pt-2'>
            <Navbar>
                <NavbarBrand href="#" onClick={() => navigate("/")}><img src={Logo} alt="goGLocal" width={163} height={32} /></NavbarBrand>
                <Nav className="d-flex flex-row ml-auto justify-center" navbar>
                    <NavItem onClick={() => navigate("/events")}>
                        <spna className={`link ${applyInactiveClass("/events") && "active-link"}`}>
                            Events
                        </spna>
                    </NavItem>
                    <hr className='verticle-line' />
                    <NavItem onClick={() => navigate("/faqs")}>
                        <spna className={`link ${applyInactiveClass("/faqs") && "active-link"}`}>
                            FAQs
                        </spna>
                    </NavItem>
                </Nav>
            </Navbar>
            <div className='container-fluid root flex-1'>
                {props.children}
            </div>
        </div>
    )
}
