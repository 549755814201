import React from 'react'
import Layout from '../../Layout'
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import QR from '../../assets/QR Code.svg';
import BottomImage from '../../assets/image 28.png';

export default function Events() {

    const navigationLabel = (p) => {
        const [Month, Year] = p.label.split(" ");
        return (
            <div className='d-flex align-items-center justify-content-between'>
                <span className='month-label'>{Month}</span>
                <span className='year-label'>{Year}</span>
            </div>
        );
    };

    return (
        <Layout>
            <div className='primary-background h-100'>
                <div class="container">
                    <div class="row gx-5">
                        <div class="col-12">
                            <p className='title'>Come Join Us As We Launch the Program in Your District</p>
                        </div>
                    </div>
                    <div class="row gx-5 mt-3">
                        <div class="col-12 col-lg-5">
                            <Calendar
                                onChange={(e) => {
                                    console.log(e)
                                }}
                                view='month'
                                navigationLabel={navigationLabel}
                                selectRange={true}
                                defaultValue={[new Date("2023-10-18"), new Date("2023-10-17")]}
                                value={[new Date("2023-10-18"), new Date("2023-10-17")]}
                            />
                        </div>
                        <div class="col-12 col-lg-7">
                            <div className='container mt-5 mt-lg-0'>
                                <div class="row g-3">
                                    <div className='col-12 col-sm-6 col-md-4'>
                                        <div className='w-100 h-100 qr-card'>
                                            <p className='qr-title text-center'>VARANSI</p>
                                            <div className='qr-content'>
                                                <div className='qr-border'>
                                                    <img src={QR} alt={"qr"} />
                                                </div>
                                                <div className='qr-divider' />
                                                <div className='mt-3 mx-auto'>
                                                    <p className='qr-content-date-time'>Date : <span>17/11/11</span></p>
                                                    <p className='qr-content-date-time'>Time : <span>11:30</span></p>
                                                </div>
                                                <p className='text-center mt-2 mb-4 qr-content-sub-title'>More Details Coming  Soon </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-12 col-sm-6 col-md-4'>
                                        <div className='w-100 h-100 qr-card'>
                                            <p className='qr-title text-center'>BHADOHI</p>
                                            <div className='qr-content'>
                                                <div className='qr-border'>
                                                    <img src={QR} alt={"qr"} />
                                                </div>
                                                <div className='qr-divider' />
                                                <div className='mt-3 mx-auto'>
                                                    <p className='qr-content-date-time'>Date : <span>17/11/11</span></p>
                                                    <p className='qr-content-date-time'>Time : <span>11:30</span></p>
                                                </div>
                                                <p className='text-center mt-2 mb-4 qr-content-sub-title'>More Details Coming  Soon </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-12 col-sm-6 col-md-4'>
                                        <div className='w-100 h-100 qr-card'>
                                            <p className='qr-title text-center'>Coming soon</p>
                                            <div className='qr-content'>
                                                <div className='qr-border'>
                                                    <img src={QR} alt={"qr"} />
                                                </div>
                                                <div className='qr-divider' />
                                                <div className='mt-3 mx-auto'>
                                                    <p className='qr-content-date-time'>Date : <span>17/11/11</span></p>
                                                    <p className='qr-content-date-time'>Time : <span>11:30</span></p>
                                                </div>
                                                <p className='text-center mt-2 mb-4 qr-content-sub-title'>More Details Coming  Soon </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='bottom-background-img' />
            </div>
        </Layout>
    )
}
